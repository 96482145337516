
import {catchError, map} from 'rxjs/operators';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpErrorHandler } from './http-error-handler';

@Injectable()
export class UserService {
  private user: User = null;
  private token: string = null;
  userLoginEvent: EventEmitter<User> = new EventEmitter();
  userLogoutEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private injector: Injector, private httpErrorHandler: HttpErrorHandler) {  }

  checkUserHasRole(role: UserRole) {
    return this.user? (this.user.roles.indexOf(role)>-1? true:false) : false;
  }

  getUser() {
    if (this.user == null) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        user.expires = user.expires? new Date(user.expires): null
        if (user.expires > (new Date())) {
          this.user = user;
          this.userLoginEvent.emit(this.user);
        }
      }
    }
    return this.user;
  }

  getUserToken() {
    return this.getUser()? this.user.token: this.token;
  }

  performLogin(fragment: Object): Observable<User> {
    this.token = <string>fragment['access_token'];

    // Http could not be injected due to cyclic dependency of defualt-request-options.service
    // this module. http://stackoverflow.com/a/39767492
    // TODO: See if we need to replace default-request-options with some custom
    // http service.
    // But Below code works!
    let http = this.injector.get(HttpClient);

    let url = environment.mysrcmUrl + 'api/v2/me/';
    return http.get(url).pipe(
      map((resp:any) => {
        let me = resp.results[0];
        this.user = new User( me.name,
                me.user_email,
                (<string>fragment['access_token']),
                (<string>fragment['scope']),
                new Date((new Date()).getTime() + (+fragment['expires_in'])*1000)
        );
        this.userLoginEvent.emit(this.user);
        localStorage.setItem('currentUser', JSON.stringify(this.user));
        return this.user;
      }
    ),catchError(error =>this.httpErrorHandler.handleError(error, this)),);;
  }

  isLoggedIn():boolean {
    return (this.getUser()!= null);
  }

  redirectToLogin() {
    localStorage.removeItem('currentUser');
    window.location.href = environment.mysrcmUrl +
    'o/authorize?state=somestate&response_type=token&client_id=' +
    environment.clientId + '&redirect_uri=' +
    window.location.protocol + '//' + window.location.host + '/login';
  }

  performLogout() {
    localStorage.removeItem('currentUser');
    this.userLogoutEvent.emit(true);
    window.location.href = environment.mysrcmUrl +
    'accounts/logout/?next=' +
    window.location.protocol + '//' + window.location.host + '/';
  }

}

export class User {
  public get roles(): UserRole[] {
    return [UserRole.SuperAdmin, UserRole.Admin]
  }
  constructor(
    public name: string,
    public email: string,
    public token: string,
    public scopes: string,
    public expires: Date,
  ) {}

}

export enum UserRole { None, User, Admin, SuperAdmin }
