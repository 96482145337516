<a [routerLink]="[soulmate.id]" class="list-group-item clearfix" [routerLinkActive]="linkActiveClass">
  <span class="pull-left image-warpper">
      <img class="img-responsive image"
           src="{{soulmate.photo?soulmate.photo:'/assets/no-image.jpg'}}"
      />
  </span>
  <div class="col-md-6">
    <h4 class="list-group-item-heading">{{soulmate.name| titleCase}}</h4>
    <p class="list-group-item-text">
      <span class="label label-info">{{soulmate.gender}}{{soulmate.age}}</span>
      <span class="label label-info">{{soulmate.date_of_birth | amDateFormat: 'DD-MMM-YYYY'}}</span>
      <span class="label label-info">{{soulmate.heightFt}}</span>
      <span class="label label-info">{{soulmate.location| titleCase}}</span>
      <span class="label label-info">{{soulmate.current_salary | currency:soulmate.salary_currency:true:'.0-0'}}</span>
      <span *ngIf="soulmate.has_fussy_preferences">
        <i class="fa fa-flag fa-lg text-danger" title="Has fussy preferences" aria-hidden="true"></i>
      </span>
    </p>
  </div>
</a>
