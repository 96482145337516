import * as moment from 'moment';
export class Soulmate {


  get location(): string {
    return [this.city_id, this.state, this.country].filter(item => item != null).join(', ') ;
  }

  get address() : string {
    return [this.street, this.street2, this.street3, this.street4, this.city_id, this.state, this.country].filter(item => item != null).join(', ');
  }

  get salaryString(): string {
    return [this.salary_currency, this.current_salary].filter(item => item != null).join(' ');
  }

  get contactNumbers(): string {
    return [this.mobile, this.phone].filter(item => item != null).join(', ');
  }

  get age(): number {
    return this.date_of_birth? moment().diff(this.date_of_birth, 'years'):null;
  }

  get heightFt(): string {
    return Math.floor(this.height / 12) + '\'' + this.height % 12 + '"';
  }

  constructor(
    public id: number,
    public name: string,
    public gender?: string,
    public photo?: string,
    public city_id?: string,
    public state?: string,
    public country?: string,
    public reference_no?: string,
    public abhyasi?: number,
    public date_of_birth?: Date,
    public date_of_joining?: Date,
    public profession?: string,
    public street?: string,
    public street2?: string,
    public street3?: string,
    public street4?: string,
    public phone?: string,
    public mobile?: string,
    public email?: string,
    public srcm_group?: string,
    public height?: number,
    public weight?: number,
    public mother_tongue?: string,
    public other_languages?: string,
    public educational_qualifications?: string,
    public current_salary?: number,
    public salary_currency?: string,
    public father_name?: string,
    public father_occupation?: string,
    public father_is_abhyasi?: boolean,
    public father_abhyasi_id?: string,
    public mother_name?: string,
    public mother_occupation?: string,
    public mother_is_abhyasi?: boolean,
    public mother_abhyasi_id ?: string,
    public more_info?: string,
    public submission_date?: Date,
    public place_of_submission?: string,
    public current_preceptor?: string,
    public coordinator?: string,
    public status?: string,
    public status_reason?: string,
    public internal_notes?: string,
    public status_is_internal?: boolean,
    public maritial_status?: string,
    public ref?: string,
    public has_fussy_preferences?: boolean,
    ) {  }

    updateFromObject(data: Object) {
      for (let field in data) {
        this[field] = data[field];
      }
    }
}
