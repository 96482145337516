import { catchError } from 'rxjs/operators/catchError';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


import { environment } from '../../../environments/environment';
import { HttpErrorHandler } from '../http-error-handler';
import { UserService } from '../user.service';


@Injectable()
export class GplaceCityWidgetService {

  constructor(private http: HttpClient,
              private httpErrorHandler: HttpErrorHandler,
              private userService: UserService,) { }

  find(place, components, val="long_name") {
    for (let query of components) {
      for (let attr of place.address_components) {
        for (let type of attr.types) {
          if (type === query) {
            return val ? attr[val] : attr;
          }
        }
      }
    }
    return null;
  }

  fetchAIMSCityDetails(formatted_address, google_place_id) {
    let url = environment.mysrcmUrl + 'api/v2/cities/geosearch/';
    return this.http.post(url, {'formatted_address':formatted_address, 'google_place_id':google_place_id}).pipe(
              catchError(error =>this.httpErrorHandler.handleError(error, this.userService)));
  }
}
