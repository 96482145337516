<div class="row space-bottom">
  <div class="col-md-2" *ngIf="forSoulmate">
    <span class="col-md-12 image-warpper text-center">
      <span class="list-group-item clearfix">
        <button type="button" class="close fa fa-times pull-right" (click)="onClose(forSoulmate)"></button>
        <img class="img-responsive image center-block" src="{{forSoulmate.photo?forSoulmate.photo:'/assets/no-image.jpg'}}"/>
          <h4 class="list-group-item-heading">{{forSoulmate.name| titleCase}}</h4>
      </span>
    </span>
  </div>
  <div class="col-md-4">
    <div class="row compare-button" *ngIf="((forSoulmate) && (candidates.length > 0))" >
      <hr/>
      <button class="btn btn-primary" (click)="onCompareClick()">Compare</button>
    </div>
  </div>
  <div class="col-md-6 pull-right" *ngIf="candidates">
    <span *ngFor="let soulmate of candidates" class="col-md-4 image-warpper text-center">
      <span class="list-group-item clearfix" >
        <button type="button" class="close fa fa-times pull-right" (click)="onClose(soulmate)"></button>
        <img class="img-responsive image center-block" src="{{soulmate.photo?soulmate.photo:'/assets/no-image.jpg'}}"/>
        <h4 class="list-group-item-heading">{{soulmate.name| titleCase}}</h4>
      </span>
    </span>
  </div>
</div>
