export class AutoCompleteResult {
  constructor(
    public field: string,
    public fieldString: string,
    public paramter: string,
    public value: string,
    public values: [number|string, string][],
    public lookupUrl?: string,
  ) {}
}

export class SearchFilter {
  constructor(
    public field: string,
    public fieldString: string,
    public parameter: string,
    public value: string|number,
    public valueString: string
  ){}

  public toQueryString():string {
    return this.field + this.parameter + '=' + this.value.toString()
  }

}

export class SortField {
  constructor(
    public field: string,
    public orderParm: string,
    public orderString: string,
    public value: string
  ) {}

  public toQueryString():string {
    return this.orderParm + '=' + this.value;
  }
}
