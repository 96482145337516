import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../common/user.service';
import { MessageType, MessagesService } from '../common/messages/messages.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private messageService: MessagesService) { }

  ngOnInit() {
    let sub = this.userService.performLogin(
        this.fragmentToObject(this.route.snapshot.fragment)
      ).subscribe(
        res => {
            if (res) {
              this.router.navigate(['/']);
            } else {
              this.messageService.pushMessage(MessageType.Error, "Login Failed!", false);
            }
            sub.unsubscribe();
        }
    );
  }

  fragmentToObject(fragment:string){
    let obj = {}
    let parts = fragment.split('&').map(p => {
      let kv = p.split('=');
      obj[kv[0]] = kv[1];
    });
    return obj;
  }

}
