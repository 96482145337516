import { EventEmitter, Injectable } from '@angular/core';

import { SoulmateService } from '../soulmate/soulmate.service';
import { Match } from './match';

@Injectable()
export class MatchService {

  private matches : Match[] = [];

  constructor(private soulmateService: SoulmateService) { }

  inDetailedViewEvent: EventEmitter<boolean> = new EventEmitter();

  getMatches(): Match[] {
    return this.matches;
  }

  getMatch(id:number): Match {
    let m = this.matches.filter(m => m.id === id);
    return m.length? m[0]:null;
  }

  addMatch(match:Match): boolean {
    if (!this.isDuplicateMatch(match)) {
      return this.createMatch(match);
    }
    return false;
  }

  createMatch(match:Match): boolean {
    //TODO: replace with api call
    match.id = this.matches.length + 1;
    this.matches.push(match);
    return true
  }

  isDuplicateMatch(match:Match): boolean {
    //TODO: replace with api call
    let duplicates = this.matches.filter(m =>
      m.groomSoulmate.id === match.groomSoulmate.id &&
      m.brideSoulmate.id === match.brideSoulmate.id &&
      m.status === match.status);
    return duplicates.length? true: false;
  }

}
