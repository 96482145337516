<div *ngIf="selectedSoulmate">
  <div class="row col-md-12">
    <button class="btn btn-success btn-sm" [disabled]="!enableCompare" (click)="selectedForCompare()">Select to Compare</button>
    <button type="button" class="close fa fa-times pull-right" (click)="onClose()"></button>
  </div>
  <div class="row col-md-12">
    <strong>
      <h5 class="pull-right">
        <i class="fa fa-flag fa-lg pull-right text-danger" *ngIf="selectedSoulmate?.has_fussy_preferences" title="Has fussy preferences"
          aria-hidden="true"></i>
        {{selectedSoulmate['reference_no']}}
      </h5>
    </strong>
  </div>
  <div class="row col-md-12">
    <h4>
      <label [ngClass]="{
    'label-default':selectedSoulmate['status'] == 'draft',
    'label-primary':selectedSoulmate['status'] == 'submitted',
    'label-success':selectedSoulmate['status'] == 'approved',
    'label-danger':selectedSoulmate['status'] == 'rejected',
    'label-warning':selectedSoulmate['status'] == 'closed'}" class="pull-right col-md-offset-10 label ">{{selectedSoulmate['status']}}</label>
    </h4>
  </div>
  <div class="row">
    <div class="col-md-8 col-sm-8 col-xs-8">
      <h1>{{selectedSoulmate?.name | titleCase}}</h1>
      <div class="row">
        <h4 class="col-md-6">{{soulmateFieldHeadings['srcm_group']}}</h4>
        <h4 class="col-md-6">{{selectedSoulmate['srcm_group']}}</h4>
      </div>
      <div class="row">
        <h4 class="col-md-6">{{soulmateFieldHeadings['ref']}}</h4>
        <h4 class="col-md-6">{{selectedSoulmate['ref']}}</h4>
      </div>
      <div class="row">
        <h4 class="col-md-6">{{soulmateFieldHeadings['date_of_birth']}}</h4>
        <h4 class="col-md-6">{{selectedSoulmate['date_of_birth'] | amDateFormat: 'DD-MMM-YYYY'}}</h4>
      </div>
      <div class="row">
        <h4 class="col-md-6">{{soulmateFieldHeadings['age']}}</h4>
        <h4 class="col-md-6">{{selectedSoulmate['age']}}</h4>
      </div>
      <div class="row">
        <h4 class="col-md-6">{{soulmateFieldHeadings['heightFt']}}</h4>
        <h4 class="col-md-6">{{selectedSoulmate['heightFt']}}</h4>
      </div>
      <hr>

      <div class="panel panel-default">
        <div class="panel-heading">Personal Information</div>
        <div class="panel-body">
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['date_of_joining']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['date_of_joining'] | amDateFormat: 'DD-MMM-YYYY'}}</h5>
            <h5 class="col-md-3">{{soulmateFieldHeadings['weight']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['weight']}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['mother_tongue']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['mother_tongue']}}</h5>
            <h5 class="col-md-3">{{soulmateFieldHeadings['other_languages']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['other_languages']}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['profession']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['profession']}}</h5>
            <h5 class="col-md-3">{{soulmateFieldHeadings['salaryString']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['current_salary'] | currency:selectedSoulmate['salary_currency']:true:'.0-0'}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['educational_qualifications']}}</h5>
            <h5 class="col-md-9">{{selectedSoulmate['educational_qualifications']}}</h5>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading">Contact Information</div>
        <div class="panel-body">
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['address']}}</h5>
            <h5 class="col-md-9">{{selectedSoulmate['address']}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['contactNumbers']}}</h5>
            <h5 class="col-md-9">{{selectedSoulmate['contactNumbers']}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['email']}}</h5>
            <h5 class="col-md-9">{{selectedSoulmate['email']}}</h5>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading">Parents Information</div>
        <div class="panel-body">
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['father_name']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['father_name']}}
              <i *ngIf="selectedSoulmate['father_is_abhyasi']" title="Is Abhyasi" class="fa fa-check-circle text-success"
                aria-hidden="true"></i>
            </h5>
            <h5 class="col-md-3">{{soulmateFieldHeadings['father_occupation']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['father_occupation']}}</h5>
          </div>
          <div class="row">
            <h5 class="col-md-3">{{soulmateFieldHeadings['mother_name']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['mother_name']}}
              <i *ngIf="selectedSoulmate['mother_is_abhyasi']" title="Is Abhyasi" class="fa fa-check-circle text-success"
                aria-hidden="true"></i>
            </h5>
            <h5 class="col-md-3">{{soulmateFieldHeadings['mother_occupation']}}</h5>
            <h5 class="col-md-3">{{selectedSoulmate['mother_occupation']}}</h5>
          </div>
        </div>
      </div>
      <div class="panel panel-default" *ngIf="selectedSoulmate['more_info']">
        <div class="panel-heading">Any other relevant information</div>
        <div class="panel-body">
          <h5>{{selectedSoulmate['more_info']}}</h5>
        </div>
      </div>
      <div class="panel panel-default" *ngIf="selectedSoulmate['status_reason']">
        <div class="panel-heading">Reason for current status</div>
        <div class="panel-body">
          <h5>{{selectedSoulmate['status_reason']}}</h5>
        </div>
      </div>

    </div>
    <div class="col-md-4 col-sm-4 col-xs-4">
      <div class="pull-right">
          <img src="{{selectedSoulmate?.photo}}" alt="" class="img-responsive image pull-right">
      </div>
    </div>
  </div>


</div>
