
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UserService } from './user.service';
import { MessageType, MessagesService } from './messages/messages.service';

@Injectable()
export class HttpErrorHandler {
  constructor(private messagesService: MessagesService) {}
  handleError(error: HttpErrorResponse| any, userService: UserService) {
    if ((error instanceof HttpErrorResponse) && (error.status === 401)) {
      userService.redirectToLogin();
    }
    let errMsg = "";
    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    this.messagesService.pushMessage(MessageType.Error,
      "Opps! Your last operation could not completed due to some error. Please retry after some time.", true);
    return observableThrowError(errMsg);
  }
}
