import { Component, OnInit } from '@angular/core';

import { Message, MessagesService } from './messages.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  public messages: Message[];

  constructor(private msgService: MessagesService) { }

  ngOnInit() {
    this.messages = this.msgService.getMessages();
  }

  onDismissMessage(m: Message) {
    this.msgService.dismissMessage(m);
  }

}
