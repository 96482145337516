import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { SoulmateComponent } from './soulmate/soulmate.component';
import { CompareSoulmatesComponent } from './compare-soulmates/compare-soulmates.component';
import { SOULMATE_ROUTES } from './soulmate/soulmate.routes';
import { MatchComponent } from './match/match.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './common/guards/login.guard';


const APP_ROUTES: Routes = [

  { path: '', redirectTo: '/soulmate', pathMatch: 'full' },
  { path: 'soulmate' , component: SoulmateComponent, children: SOULMATE_ROUTES, canActivate: [LoginGuard]},
  { path: 'compare-soulmates/:fromid/:toid', component: CompareSoulmatesComponent, canActivate: [LoginGuard]},
  { path: 'matches', component: MatchComponent, canActivate: [LoginGuard]},
  { path: 'login', component: LoginComponent},
  { path: '**', redirectTo: ''}

];

export const routing = RouterModule.forRoot(APP_ROUTES);
