import {of as observableOf,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { NgLocalization } from '@angular/common';
import { HttpClient } from '@angular/common/http';



import { Soulmate } from './soulmate';
import { SearchServiceInterface } from '../common/search/search.service.interface';
import { AutoCompleteResult, SearchFilter, SortField } from '../common/search/search';
import { UserService } from '../common/user.service';
import { environment } from '../../environments/environment';
import { HttpErrorHandler } from '../common/http-error-handler';
import { ProgressBarService } from '../common/progress-bar.service';
import { PaginationServiceInterface } from '../common/pagination.service.interface';

@Injectable()
export class SoulmateService implements SearchServiceInterface, PaginationServiceInterface  {
  private soulmates: Soulmate[] = [];
  private forSoulmate: Soulmate = null;
  private compareCandidates: Soulmate[] = [];

  compareSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  doCompareEvent: EventEmitter<Soulmate[]> = new EventEmitter();
  inDetailedViewEvent: EventEmitter<boolean> = new EventEmitter();
  genderFilter: string = "F";
  storedFilters: SearchFilter[] = [];
  storedSortFilters: SortField[] = [];
  pageCount: number = 1;
  currentPage: number = 1;
  pageSize: number = 10;
  resultCount: number = 0;
  searchListChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private userService: UserService,
              private http: HttpClient,
              private httpErrorHandler: HttpErrorHandler,
              private progressBarService: ProgressBarService) { }

  getSoulmates(): Soulmate[] {
    return this.soulmates;
  }

  getSoulmate(id: number): Observable<Soulmate> {
    let url = environment.mysrcmUrl + "api/v2/soulmates/" + id + "/";
    this.progressBarService.progressBarEvent.emit(true);
    return this.http.get(url, ).pipe(map(
      res => {
        this.progressBarService.progressBarEvent.emit(false)
        return this.reMappingFields(res)
      }
    ),catchError(error =>this.httpErrorHandler.handleError(error, this.userService)),);
    // return this.soulmates.filter(s => s.id === id)[0];
  }

  getSelectedSoulmates(): [Soulmate, Soulmate[]] {
    return [this.forSoulmate, this.compareCandidates];
  }

  getComparePossible(soulmate: Soulmate):boolean {
    return (
      (this.forSoulmate == null) ||  //if forSoulmate is not populated then allow compare
      // else if gender is opposit of for soulmate and compare slider has space.
      ((this.compareCandidates.length<3) && (this.forSoulmate.gender !== soulmate.gender) &&
        (this.compareCandidates.filter(s => s.id === soulmate.id).length === 0)))
  }

  selectedForCompare(soulmate: Soulmate) {
    if (this.forSoulmate == null){
      this.forSoulmate = soulmate;
    } else {
      if ((this.forSoulmate.gender !==  soulmate.gender) && (this.compareCandidates.length < 3)){
        this.compareCandidates.push(soulmate);
      }
    }
    this.compareSelectionChanged.emit(true);
  }

  removedFromCompare(soulmate: Soulmate) {
    if(soulmate == this.forSoulmate){
      this.forSoulmate = null;
      this.compareCandidates = [];
    }else {
      this.compareCandidates.splice(this.compareCandidates.indexOf(soulmate), 1)
    }
    this.compareSelectionChanged.emit(true);
  }

  performCompare(soulmates: Soulmate[]){
    this.doCompareEvent.emit(soulmates);
  }

  setInDetailedView(flag: boolean) {
    this.inDetailedViewEvent.emit(flag);
  }

  lookupParameters(result: AutoCompleteResult, queryParm:string): Observable<[number, string][]> {
    if ((queryParm.length <= 2)|| (!result.lookupUrl)) {return observableOf([])}

    let url =  environment.mysrcmUrl + result.lookupUrl + queryParm;
    this.progressBarService.progressBarEvent.emit(true);
    return this.http.get(url, ).pipe(map( (resp:any) => {
      this.progressBarService.progressBarEvent.emit(false);
      return resp.results.map(res => [res.id, res.name])
    }
    ),catchError(error =>this.httpErrorHandler.handleError(error, this.userService)),);
  }

  setGenderFilter(gender: string) {
    this.currentPage = 1;
    this.genderFilter = gender;
    let obs = this._search(this.storedFilters, this.storedSortFilters);
    let sub = obs.subscribe(
      (res) => {sub.unsubscribe();}
    );
  }

  goToFirstPage(){
    this.setPageCount(1);
  }
  goToLastPage(){
    let page = Math.ceil(this.resultCount/this.pageSize);
    this.setPageCount(page);
  }
  goToNextPage(){
    this.setPageCount(this.currentPage + 1);
  }
  goToPreviousPage(){
    this.setPageCount(this.currentPage - 1);
  }

  setPageCount(count: number){
    this.currentPage = count;
    let obs = this._search(this.storedFilters, this.storedSortFilters);
    let sub = obs.subscribe(
      (res) => {sub.unsubscribe();}
    );
  }
  search(filters: SearchFilter[], sortFilters: SortField[] = [], ): Observable<any[]> {
    this.currentPage = 1;
    return this._search(filters, sortFilters)
  }
  _search(filters: SearchFilter[], sortFilters: SortField[] = []): Observable<any[]> {
    //Convert the Search Filter to Query Param
    this.storedFilters = filters;
    this.storedSortFilters = sortFilters;

    if (!sortFilters.length) {
      sortFilters.push(this.sortFields[0]);
    }

    let queryParm:string[] = [];
    filters.map(
      (filter:SearchFilter) => {
        queryParm.push(filter.toQueryString());
      }
    );

    queryParm.push("page=" + this.currentPage);
    queryParm.push("page_size=" + this.pageSize);
    queryParm.push("gender=" + this.genderFilter);
    queryParm.push("status=approved");

    sortFilters.map(
      (sortFilter:SortField) => {
        queryParm.push(sortFilter.toQueryString());
      }
    );

    let url = environment.mysrcmUrl + "api/v2/soulmates/" + "?" + queryParm.join("&");
    this.progressBarService.progressBarEvent.emit(true);
    return this.http.get(url, ).pipe(map(
      (res: any) => {
        this.soulmates = res.results.map(result => this.reMappingFields(result))
        this.resultCount = res.count;
        this.searchListChanged.emit(true);
        this.progressBarService.progressBarEvent.emit(false);
        return this.soulmates;
      }
    ),catchError(error =>this.httpErrorHandler.handleError(error, this.userService)),);
  }

  reMappingFields(input: any) : Soulmate {
    let m2o_fields = ['city_id', 'state', 'country', 'srcm_group', 'abhyasi',
                      'current_preceptor', 'coordinator'];
    let date_fields = ['date_of_birth', 'submission_date', 'date_of_joining'];
    for (let field of date_fields) {
      if (input.hasOwnProperty(field)){
        input[field] = input[field]? new Date(input[field]): null;
      }
    }

    for (let field of m2o_fields) {
      if (input.hasOwnProperty(field)){
        input[field] = input[field]? input[field]['name']: null;
      }
    }
    let soulmate:Soulmate = new Soulmate(input.id, input.name)
    soulmate.updateFromObject(input);
    return soulmate;
  }

  public soulmateFieldHeadings = {
    "gender":"Gender",
    "age":"Age",
    "maritial_status": "Maritial Status",
    "reference_no": "Soulmate Reference No",
    "date_of_birth": "Date of Birth",
    "date_of_joining": "Date of Joining",
    "educational_qualifications": "Educational Qualifications",
    "profession": "Current Profession",
    "address": "Address",
    "contactNumbers": "Contact Numbers",
    "email": "Email",
    "srcm_group": "Center",
    "heightFt": "Height (Ft)",
    "weight": "Weight (kgs)",
    "mother_tongue": "Mother Toungue",
    "other_languages": "Other Languages",
    "salaryString": "Annual Income",
    "father_name": "Father's Name",
    "father_occupation": "Father's Occupation",
    "father_is_abhyasi": "Is Father an Abhyasi",
    "father_abhyasi_id": "Farther's Abhyasi Id",
    "mother_name": "Mother's Name",
    "mother_occupation": "Mother's Occupation",
    "mother_is_abhyasi": "Is Mother an Abhyasi",
    "mother_abhyasi_id": "Mother's Abhyasi Id",
    "more_info": "Other Info (Health Info)",
    "submission_date": "Date of Submission",
    "place_of_submission": "Place of Submission",
    "current_preceptor": "Current Preceptor",
    "coordinator": "Soulmate Coordinator",
    "status": "Status",
    "status_reason": "Status Reason",
    "internal_notes": "Internal Notes",
    "ref": "ID Card",
  }

  public searchFields = {
    'name': {'type':'string', 'string':'Name', 'paramters': [['contains', '__istartswith']]},
    'age': {'type':'number', 'min': 18, 'max': 50, 'string':'Age', 'paramters': [['>','__gt'],['<','__lt'],['=','']]},
    // 'height': {'type':'number', 'min':3, 'max': 8, 'string':'Height (Ft)', 'paramters': [['>','__gt'],['<','__lt'],['=','']]},
    'country': {'type':'lookup', 'string':'Country', 'paramters': [['=', '']], 'api':'api/v2/countries/?name__icontains='},
    'state': {'type':'lookup', 'string':'State', 'paramters': [['=', '']], 'api':'api/v2/states/?name__icontains='},
    'city_id': {'type':'lookup', 'string':'City', 'paramters': [['=', '']], 'api':'api/v2/cities/?name__icontains='},
    'reference_no': {'type':'string|number', 'string':'Reference No', 'paramters': [['contains', '__icontains']]},
  }

  public sortFields: SortField[] =  [
    new SortField('age', 'order', 'Age: low to high', 'age'),
    new SortField('age', 'order', 'Age: high to low', '-age'),
    new SortField('height', 'order', 'Height: low to high', 'height'),
    new SortField('height', 'order', 'Height: high to low', '-height'),
    new SortField('salary', 'order', 'Salary: low to high', 'income_inr'),
    new SortField('salary', 'order', 'Salary: high to low', '-income_inr'),
  ]

}
