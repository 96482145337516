import { Injectable } from '@angular/core';

@Injectable()
export class MessagesService {

  private messages: Message[] = []

  constructor() { }

  pushMessage(msgType: MessageType,
    messageString: string,
    dismissable?: boolean) {
    this.messages.push(new Message(msgType, messageString, dismissable));
  }

  getMessages() {
    return this.messages;
  }

  clearMessages() {
    this.messages.splice(0, this.messages.length);
  }

  dismissMessage(m: Message) {
    this.messages.splice(this.messages.indexOf(m), 1);
  }

}

export class Message {

  constructor(
  public messageType: MessageType,
  public message: string,
  public dismissable: boolean) {}

  public get cssClass(): string {
    let css: string[] = []
    css.push(MessageCssClass[this.messageType])
    if (this.dismissable) {
      css.push('alert-dismissible');
    }
    return css.join(' ');
  }

}
export enum MessageType {
  Info, Success, Warning, Error
}
export const MessageCssClass: string[] = ['alert-info', 'alert-success', 'alert-warning', 'alert-danger'];
