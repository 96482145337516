import { Component, Input, OnInit } from '@angular/core';

import {Soulmate} from '../soulmate'

@Component({
  selector: 'app-soulmate-item',
  templateUrl: './soulmate-item.component.html',
  styleUrls: ['./soulmate-item.component.css']
})
export class SoulmateItemComponent implements OnInit {
  @Input()
  soulmate: Soulmate;
  @Input()
  linkActiveClass: string = "active";

  constructor() { }

  ngOnInit() {
  }

}
