import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Soulmate } from '../soulmate';
import { SoulmateService } from '../soulmate.service';

@Component({
  selector: 'app-soulmate-list',
  templateUrl: './soulmate-list.component.html'
})
export class SoulmateListComponent implements OnInit, OnDestroy {
  soulmates: Soulmate[] = [];
  subscription: Subscription;

  constructor(public soulmateService: SoulmateService) { }

  ngOnInit() {
    this.subscription = this.soulmateService.searchListChanged.subscribe(
      value => {
        this.soulmates = this.soulmateService.getSoulmates();
        document.body.scrollTop = 0;
      }
    );
    let sub = this.soulmateService.search([]).subscribe(
      soulmates => {
        sub.unsubscribe()
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
