<app-search></app-search>

<div class='row'>
  <div [class]="inDetailView? 'col-md-4': 'col-md-12'">
    <app-match-list></app-match-list>
  </div>
  <div class="col-md-8" >
    <router-outlet></router-outlet>
  </div>
</div>
