import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgLocalization } from '@angular/common';

import { SearchServiceInterface } from './search.service.interface';
import { AutoCompleteResult, SearchFilter, SortField } from './search';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input()
  private searchService: SearchServiceInterface;

  @Input()
  public placeholder: string = "Search";


  public appliedFilters : SearchFilter[] = [];
  private appliedSortFilters: SortField[] = [];
  public autoCompleteResults : AutoCompleteResult[]= null;
  private waitAndCall: any;
  private prevValue: string = "";
  @ViewChild('searchInput')
  private searchElement: any;
  public sortFields: SortField[];
  private sub: Subscription;
  private selectedAutoCompleteResult:number;
  private selectedAutoCompleteValue:number;

  constructor(private elementRef: ElementRef) { }

  onKeyup(event, dropDown) {
    //this.searchElement = event.target;
    if (event.target.value != "" &&
       (event.target.value.length >= 3 || !isNaN(event.target.value)) &&
        event.target.value != this.prevValue) {
      let t = this.waitAndCall? clearTimeout(this.waitAndCall): true
      this.waitAndCall = setTimeout(() => {
        this.autoCompleteResults = this.getAutoCompleteResults(event.target.value);
        this.selectedAutoCompleteResult = 0
        },200 );
        
    }
    if (this.autoCompleteResults) {
      let result = this.autoCompleteResults[this.selectedAutoCompleteResult]
      if (event.key == 'ArrowDown' && this.autoCompleteResults) {
        if (result.values && result.values.length && this.selectedAutoCompleteValue != result.values.length-1 ) {
          this.selectedAutoCompleteValue += 1;
        } else {
          this.selectedAutoCompleteValue = 0;
          if (this.selectedAutoCompleteResult === this.autoCompleteResults.length-1) {
            this.selectedAutoCompleteResult = 0;
          } else {
            this.selectedAutoCompleteResult += 1;
          }
        }
      }

      if (event.key == 'ArrowUp' && this.autoCompleteResults) {
        if (result.values && result.values.length && this.selectedAutoCompleteValue != 0 ) {
          this.selectedAutoCompleteValue -= 1;
        } else {
          this.selectedAutoCompleteValue = 0;
          if (this.selectedAutoCompleteResult === 0) {
            this.selectedAutoCompleteResult = this.autoCompleteResults.length-1;
          } else {
            this.selectedAutoCompleteResult -= 1;
          }
        }
      }

      if (event.key == 'Enter' && this.autoCompleteResults) {
        
        if (result.lookupUrl) {
          this.performLookup(result);
        } else {
          if (result.values && result.values.length) {
            this.onSelectResult(event.target, result, result.values[this.selectedAutoCompleteValue]);
          } 
          if (result.value) {
            this.onSelectResult(event.target, result, )
          }
        }
      }
    }

    if (event.key == 'Backspace') {
      if (event.target.value.length < 3) {
        this.autoCompleteResults = null;
      }
    }
    this.prevValue = event.target.value
  }

  mouseoverResult(result: AutoCompleteResult) {
    this.autoCompleteResults.map((r:AutoCompleteResult, i:number) => {
      if (r == result) {
        this.selectedAutoCompleteResult = i;
      } 
    })
  }

  mouseoverValue(result: AutoCompleteResult, value:[number|string, string]) {
    result.values.map((v:[number|string, string],i:number) => {
      if (v == value){
        this.selectedAutoCompleteValue = i;
      }
    });
  }


  performLookup(result: AutoCompleteResult) {
    this.searchService.lookupParameters(result, this.prevValue).subscribe(
        (response:[number, string][]) => {
          result.values = response;
          result.lookupUrl = null;
          result.fieldString += " is";
        }
    );
  }

  ngOnInit() {
    if (!this.searchService) {
      console.log("Not correctly configured")
    }
    this.sortFields = this.searchService.sortFields;
    this.sub = this.searchService.searchListChanged.subscribe(
      res => this.searchElement.nativeElement.focus()
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSelectResult(searchInput: any, result: AutoCompleteResult, value?: [number|string, string]) {
    this.autoCompleteResults = null;
    this.prevValue = "";
    searchInput.value = ""
    searchInput.focus();
    this.appliedFilters.push(new SearchFilter(
      result.field,
      result.fieldString,
      result.paramter[1],
      value? value[0]: result.value,
      value? value[1]: result.value
    ));
    this.searchService.search(this.appliedFilters, this.appliedSortFilters).subscribe(
      data => {}
    );
  }

  setSortFilter(index: number) {
    //TODO: Call from frontend when sortfilter is chosen
    // this.appliedSortFilters.splice(this.appliedFilters.indexOf(
    //     this.appliedSortFilters.find(f => f.field === filter.field)), 1);
    this.sortFields[index]
    this.appliedSortFilters = [this.sortFields[index]];
    this.searchService.search(this.appliedFilters, this.appliedSortFilters).subscribe(
      data => {}
    );
  }

  removeFilter(filter: SearchFilter) {
    this.appliedFilters.splice(this.appliedFilters.indexOf(filter), 1);
    this.searchService.search(this.appliedFilters, this.appliedSortFilters).subscribe(
      data => {}
    );
  }

  getAutoCompleteResults(query: any, suppressAuto: boolean = false):AutoCompleteResult[] {
    let result = []
    for (let key in this.searchService.searchFields) {
      let value = this.searchService.searchFields[key];
      switch(value['type']) {
        case 'string':
          if (!isNaN(query)) { break; }
        case 'string|number':
          for (let parm of value['paramters']) {
            if (this.appliedFilters.filter(f => f.field === key && f.parameter === parm[1]).length > 0) {continue;}
            let fieldString = value['string'] + ' ' + parm[0] + ' ';
            result.push(new AutoCompleteResult(key, fieldString, parm, query, null))
          }
          break;
        case 'number':
          if (isNaN(query)) { break; }
          if (value.hasOwnProperty('max') && query > value['max']) {break;}
          if (value.hasOwnProperty('min') && query < value['min']) {break;}
          for (let parm of value['paramters']) {
            if (this.appliedFilters.filter(f => f.field === key && f.parameter === parm[1]).length > 0) {continue;}
            let fieldString = value['string'] + ' ' + parm[0] + ' ';
            result.push(new AutoCompleteResult(key, fieldString, parm, query, null))
          }
          break;
        case 'list':
          let optionValues:[number|string, string][] = []
          if (this.appliedFilters.filter(f => f.field === key && f.parameter === value['paramters'][0][1]).length > 0) {
            continue;
          }
          if (isNaN(query)) {
             optionValues = value['values']
                            .filter(v =>
                              v[1].toLowerCase().search(query.toLowerCase()) > -1
                            );
          } else {
            optionValues = value['values']
                           .filter(v =>
                             v[1] == query );
          }
          if (optionValues.length > 0) {
            result.push(new AutoCompleteResult(key, value['string'], value['paramters'][0], null, optionValues))
          }
          break;
        default:
          if (this.appliedFilters.filter(f => f.field === key && f.parameter === value['paramters'][0][1]).length > 0) {
            continue;
          }
          if (isNaN(query)) {
            result.push(new AutoCompleteResult(key, value['string'], value['paramters'][0], null, null, value['api']))
          }
      }
    }
    return result
  }

}
