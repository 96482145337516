import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from '../user.service';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(
      // private router: Router,
      private userService: UserService
    ) { }

    canActivate() {
        if (this.userService.isLoggedIn()) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page
        this.userService.redirectToLogin();
        return false;
    }
}
