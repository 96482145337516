<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-2">
    <img *ngIf="forSoulmate?.photo" src="{{forSoulmate?.photo}}" alt="" class="img-responsive image">
    <h3 class="pull-left">{{forSoulmate?.name | titleCase}}</h3>
  </div>
  <div *ngFor="let soulmate of compareCandidates">
    <div class="col-md-2">
      <img *ngIf="soulmate?.photo" src="{{soulmate?.photo}}" alt="" class="img-responsive image"/>
      <h3 class="pull-left">{{soulmate?.name | titleCase}}</h3>
    </div>
  </div>
</div>
<hr>
<div class="row" *ngFor="let field of soulmateFields">
  <div class="col-md-4">
    <p><b>{{soulmateFieldHeadings[field]}}</b></p>
  </div>
  <div class="col-md-2 text-left">
    <div *ngIf="forSoulmate">
      <p *ngIf="dateFields.indexOf(field) == -1">{{forSoulmate[field]}}</p>
      <p *ngIf="dateFields.indexOf(field) > -1">{{forSoulmate[field] | amDateFormat: 'DD-MMM-YYYY'}}</p>
    </div>
  </div>
  <div *ngFor="let soulmate of compareCandidates">
    <div class="col-md-2 text-left">
      <div *ngIf="compareCandidates">
        <p *ngIf="dateFields.indexOf(field) == -1">{{soulmate[field]}}</p>
        <p *ngIf="dateFields.indexOf(field) > -1">{{soulmate[field] | amDateFormat: 'DD-MMM-YYYY'}}</p>
      </div>
    </div>
  </div>
</div>
