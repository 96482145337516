import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SoulmateService } from '../soulmate/soulmate.service';
import { Soulmate } from '../soulmate/soulmate';
import { MatchService } from '../match/match.service';
import { Match, MatchStatus } from '../match/match';
import { MessageType, MessagesService } from '../common/messages/messages.service';

@Component({
  selector: 'app-compare-soulmates',
  templateUrl: './compare-soulmates.component.html',
  styleUrls: ['./compare-soulmates.component.css']
})
export class CompareSoulmatesComponent implements OnInit, OnDestroy {
  private subscription;
  public forSoulmate: Soulmate;
  public compareCandidates: Soulmate[] = []

  private soulmateFieldHeadings = {};
  public soulmateFields : string[] ;
  private dateFields = ['date_of_birth', 'submission_date', 'date_of_joining'];

  private message: string;

  constructor(private soulmateService: SoulmateService ,
    private route: ActivatedRoute,
    private matchService: MatchService,
    private router: Router,
    private msgService: MessagesService) { }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(
      (params) => {
        if (params.hasOwnProperty('fromid')) {
          this.soulmateService.getSoulmate(+params['fromid']).subscribe(
            res => this.forSoulmate = res
          );
        }
        if (params.hasOwnProperty('toid')) {
          params['toid'].split(",").map(id  => {
            this.soulmateService.getSoulmate(+id).subscribe(
              res => this.compareCandidates.push(res)
            );
          });
        }
      }
    );
    this.soulmateFieldHeadings = this.soulmateService.soulmateFieldHeadings;
    this.soulmateFields = Object.keys(this.soulmateFieldHeadings)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // createMatch() {
  //   let match: Match = new Match(0, this.groomSoulmate, this.brideSoulmate,
  //   MatchStatus.Proposed);
  //   this.message = this.matchService.addMatch(match)? null: "Match Creation Failed. Possibly Duplicate record.";
  //   if (this.message===null) {
  //     this.router.navigate(['/matches']);
  //   } else {
  //     this.msgService.pushMessage(MessageType.Error, this.message, true);
  //   }
  // }

}
