import { Component, OnInit, OnDestroy, Input, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {Soulmate } from '../soulmate';
import { SoulmateService } from '../soulmate.service';

@Component({
  selector: 'app-soulmate-detail',
  templateUrl: './soulmate-detail.component.html',
  styleUrls: ['./soulmate-detail.component.css']
})
export class SoulmateDetailComponent implements OnInit, OnDestroy {
  selectedSoulmate: Soulmate;
  private subscription: Subscription;
  private subscription2: Subscription;
  private dateFields = ['date_of_birth', 'submission_date', 'date_of_joining'];
  private enableCompare: boolean = false;

  private soulmateFieldHeadings;
  private soulmateFields : string[] ;


  constructor(private route: ActivatedRoute,
              private soulmateService: SoulmateService,
              private router: Router) { }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(
      parms => {
        this.soulmateService.getSoulmate(+parms['id']).subscribe(
          (s:Soulmate) => {
            this.selectedSoulmate = s;
            this.enableCompare = this.soulmateService.getComparePossible(s);
            if (this.selectedSoulmate) {
              this.soulmateService.setInDetailedView(true);
              document.body.scrollTop = 0;
            }
          }
        );
      }
    );
    this.subscription2 = this.soulmateService.compareSelectionChanged.subscribe(
      flag => {
        this.enableCompare = this.soulmateService.getComparePossible(this.selectedSoulmate);
      }
    )
    this.soulmateFieldHeadings = this.soulmateService.soulmateFieldHeadings;
    this.soulmateFields = Object.keys(this.soulmateFieldHeadings)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.soulmateService.setInDetailedView(false);
  }

  selectedForCompare() {
    this.soulmateService.selectedForCompare(this.selectedSoulmate);
  }

  onClose() {
    this.router.navigate(['']);
  }





}
