import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header.component';
import { SoulmateComponent } from './soulmate/soulmate.component';
import { SoulmateListComponent } from './soulmate/soulmate-list/soulmate-list.component';
import { SoulmateItemComponent } from './soulmate/soulmate-list/soulmate-item.component';
import { SoulmateDetailComponent } from './soulmate/soulmate-detail/soulmate-detail.component';
import { DropdownDirective } from './common/dropdown.directive';
import { routing } from './app.routing';
import { SoulmateCompareSliderComponent } from './soulmate/soulmate-compare-slider/soulmate-compare-slider.component';
import { SoulmateService } from './soulmate/soulmate.service';
import { CompareSoulmatesComponent } from './compare-soulmates/compare-soulmates.component';
import { MatchService } from './match/match.service';
import { MatchComponent } from './match/match.component';
import { MatchListComponent } from './match/match-list/match-list.component';
import { MatchItemComponent } from './match/match-list/match-item.component';
import { MessagesComponent } from './common/messages/messages.component';
import { MessagesService } from './common/messages/messages.service';
import { UserService } from './common/user.service';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './common/guards/login.guard';
import { SearchComponent } from './common/search/search.component';
import { DefaultRequestOptions } from './common/default-request-options.service';
import { TitleCase } from './common/pipes/title-case.pipe';
import { HttpErrorHandler } from './common/http-error-handler';
import { ProgressBarComponent,  } from './common/progress-bar.component';
import { ProgressBarService } from './common/progress-bar.service';
import { PaginationComponent } from './common/pagination.component';
import { ImageUploadComponent } from './common/image-upload/image-upload.component';
import { GplaceCityWidgetDirective } from './common/gplace-city-widget/gplace-city-widget.directive';
import { GplaceCityWidgetService } from './common/gplace-city-widget/gplace-city-widget.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SoulmateComponent,
    SoulmateListComponent,
    SoulmateItemComponent,
    SoulmateDetailComponent,
    DropdownDirective,
    SoulmateCompareSliderComponent,
    CompareSoulmatesComponent,
    MatchComponent,
    MatchListComponent,
    MatchItemComponent,
    MessagesComponent,
    LoginComponent,
    SearchComponent,
    TitleCase,
    ProgressBarComponent,
    PaginationComponent,
    ImageUploadComponent,
    GplaceCityWidgetDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MomentModule,
    routing,
    ModalModule.forRoot(),
  ],
  providers: [
    SoulmateService,
    MatchService,
    MessagesService,
    UserService,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultRequestOptions,
      multi: true,
    },
    HttpErrorHandler,
    ProgressBarService,
    GplaceCityWidgetService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
