import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatchService } from './match.service';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.css']
})
export class MatchComponent implements OnInit, OnDestroy {

  public inDetailView: boolean;
  private subscription: Subscription

  constructor(private matchService: MatchService) { }

  ngOnInit() {
    this.subscription = this.matchService.inDetailedViewEvent.subscribe(
      (flag:boolean) => this.inDetailView = flag
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
