<div class="row">
  <div class="col-xs-12">
    <ul class="list-group">
      <app-match-item
        *ngFor="let match of matches; let i = index"
        [match]="match" [linkActiveClass]='"active"'>
      </app-match-item>
    </ul>
  </div>
</div>
