import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService, User } from './common/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public user: User = null;
  private subscription: Subscription;
  public env = environment;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.subscription = this.userService.userLoginEvent.subscribe(
      user => this.user = user
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLogout() {
    this.userService.performLogout();
  }

}
