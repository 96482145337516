import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';

import { PaginationServiceInterface} from './pagination.service.interface';

@Component({
  selector: 'app-pagination',
  template: `
  <span *ngIf="!totalPages">No Records found.</span>
  <nav *ngIf="totalPages">
    <ul class="pager">
      <li><button type="button" class="btn btn-default fa fa-angle-double-left" (click)="onClickFirst()" [disabled]="currentPage==1"></button></li>
      <li><button type="button" class="btn btn-default fa fa-angle-left" (click)="onClickPrevious()" [disabled]="currentPage==1"></button></li>
        <span >Page <strong>{{currentPage}}</strong> of <strong>{{totalPages}}</strong> </span>
      <li><button type="button" class="btn btn-default fa fa-angle-right" (click)="onClickNext()" [disabled]="currentPage==totalPages"></button></li>
      <li><button type="button" class="btn btn-default fa fa-angle-double-right" (click)="onClickLast()" [disabled]="currentPage==totalPages"></button></li>
    </ul>
  </nav>
  `,
  styles: [`
    .btn-default {
      color: #337ab7;
    }
    `]
})
export class PaginationComponent implements OnInit, OnDestroy {

  currentPage: number;
  totalPages: number;
  @Input()
  private paginationService: PaginationServiceInterface;
  sub: Subscription;

  constructor() { }

  ngOnInit() {
    if (!this.paginationService) {
      console.log("Not correctly configured");
    }
    this.sub = this.paginationService.searchListChanged.subscribe(
      flag => {
        this.currentPage = this.paginationService.currentPage;
        this.totalPages  = Math.ceil(this.paginationService.resultCount / this.paginationService.pageSize);
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onClickFirst() {
    this.paginationService.goToFirstPage();
  }
  onClickLast() {
    this.paginationService.goToLastPage();
  }
  onClickPrevious() {
    this.paginationService.goToPreviousPage();
  }
  onClickNext() {
    this.paginationService.goToNextPage();
  }


}
