<div class="container">
  <app-soulmate-compare-slider></app-soulmate-compare-slider>

    <div class="btn btn-group row">
      <button class="btn"
        [ngClass]="{'btn-primary':selectedGender == 'M'}"
        (click)="onGenderSelect('M')"><i class="fa fa-male" aria-hidden="true"></i> Groom Candidates
      </button>
      <button class="btn"
        [ngClass]="{'btn-primary':selectedGender == 'F'}"
        (click)="onGenderSelect('F')"><i class="fa fa-female" aria-hidden="true"></i> Bride Candidates
      </button>
  </div>


  <app-search [searchService]="soulmateService" [placeholder]="'Search Soulmate'"></app-search>
  <span class="row"></span>
  <div class='row'>
    <div *ngIf="!inDetailView">
      <app-soulmate-list></app-soulmate-list>
    </div>
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
