import { Component, OnInit, EventEmitter, OnDestroy, Input, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import {ProgressBarService} from './progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  template: `
  <div class="progress2" >
    <div *ngIf="progressBar" class="progress-bar progress-bar-success progress-bar-striped active"
    role="progressbar" aria-valuenow="100" aria-valuemin="0"
    aria-valuemax="100" [ngStyle]="{'width': progressValue + '%'}">
    </div>
  </div>
  `,
  styles: [`
    .progress2 {
      margin-top: -20px;
      margin-bottom: 0px;
      height:3px;
    }
    .progress-bar {
      -webkit-animation: progress-bar-stripes 1s linear infinite;
      -o-animation: progress-bar-stripes 1s linear infinite;
      animation: progress-bar-stripes 1s linear infinite;
      height:3px;
    }

  `],
})

export class ProgressBarComponent implements OnInit, OnDestroy {
  progressBar: boolean = true;
  progressValue: number = 20;
  subscription: Subscription;

  constructor(private progressBarService: ProgressBarService) { }

  ngOnInit() {
    this.subscription = this.progressBarService.progressBarEvent.subscribe(
      (progressBar: boolean) => {
        this.progressValue = 20;
        if (progressBar) {
          this.progressBar = progressBar
          setTimeout(() => this.progressValue += 10, 5);
          setTimeout(() => this.progressValue += 10, 10);
          setTimeout(() => this.progressValue += 10, 15);
          setTimeout(() => this.progressValue += 10, 20);
          setTimeout(() => this.progressValue += 10, 30);
          setTimeout(() => this.progressValue += 10, 40);
          setTimeout(() => this.progressValue += 19, 50);
        } else {
          this.progressValue = 100;
          setTimeout(() => {
            this.progressBar = progressBar;
          }, 1000);
        }
      }
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
