import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import {Soulmate} from './soulmate'
import {SoulmateService}  from './soulmate.service';


@Component({
  selector: 'app-soulmate',
  templateUrl: './soulmate.component.html',
  styleUrls: ['./soulmate.component.css'],
  providers: []
})
export class SoulmateComponent implements OnInit, OnDestroy {
  inDetailView: boolean;
  subscription: Subscription;
  selectedGender: string = "F";

  constructor(public soulmateService: SoulmateService,
              private router: Router,
  ) { }

  onGenderSelect(gender: string) {
    this.selectedGender = gender;
    this.soulmateService.setGenderFilter(gender);
    this.router.navigate(['/soulmate']);
  }

  back() {
    this.router.navigate(['/soulmate']);
  }

  ngOnInit() {
    this.subscription = this.soulmateService.inDetailedViewEvent.subscribe(
      (inDetailedView: boolean) => this.inDetailView = inDetailedView
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
