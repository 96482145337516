import {Soulmate} from '../soulmate/soulmate';

export class Match {
  constructor(
    public id: number = 0,
    public groomSoulmate: Soulmate,
    public brideSoulmate: Soulmate,
    public status: MatchStatus = MatchStatus.Draft,
    public groomAccepted: boolean = false,
    public brideAccepted: boolean = false,
    public weddingDate?: Date,
    public rejectedBy?: MatchRejectedBy,
    public rejectedReasonGroom?: string,
    public rejectedReasonBride?: string,
    ) {}
}

export enum MatchStatus {
  Draft,
  Proposed,
  Accepted,
  WeddingFixed,
  Rejected,
  Closed
}

export enum MatchRejectedBy {
  Bride,
  Groom
}
