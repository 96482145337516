import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {SoulmateService} from '../soulmate.service';
import {Soulmate} from '../soulmate';

import {Subscription } from 'rxjs';

@Component({
  selector: 'app-soulmate-compare-slider',
  templateUrl: './soulmate-compare-slider.component.html',
  styleUrls: ['./soulmate-compare-slider.component.css'],
})
export class SoulmateCompareSliderComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  forSoulmate: Soulmate;
  candidates: Soulmate[] = []

  constructor(private soulmateService: SoulmateService, private router: Router) { }

  ngOnInit() {
    [this.forSoulmate, this.candidates] = this.soulmateService.getSelectedSoulmates();
    this.subscription = this.soulmateService.compareSelectionChanged.subscribe (
        (soulmate: Soulmate) =>
          [this.forSoulmate, this.candidates] = this.soulmateService.getSelectedSoulmates()
      )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClose(soulmate) {
    this.soulmateService.removedFromCompare(soulmate);
  }

  onCompareClick() {
    if (this.forSoulmate && this.candidates) {
      this.router.navigate(['/compare-soulmates' , this.forSoulmate.id  , this.candidates.map(s=> s.id).join(',')]);
      // this.soulmateService.performCompare([this.groomSoulmate, this.brideSoulmate]);
    }
  }

}
