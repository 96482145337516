import { Component, Input, OnInit } from '@angular/core';

import { Match, MatchStatus } from '../match';

@Component({
  selector: 'app-match-item',
  templateUrl: './match-item.component.html',
  styles: [`
    .image {
      height: 50px;
    }
  `]
})
export class MatchItemComponent implements OnInit {

  @Input()
  public match: Match;

  @Input()
  public linkActiveClass: string = 'active';

  get matchStatus() {
    return this.match? (<any>MatchStatus)[this.match.status] : null;
  }

  constructor() { }

  ngOnInit() {

  }

}
