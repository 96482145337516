<a [routerLink]="[match.id]" class="list-group-item clearfix"
[routerLinkActive]="linkActiveClass">
  <div class="pull-left ">
    <span class="image-warpper pull-left">
        <img class="img-responsive image"
             src="{{match.brideSoulmate.photo}}"
        />
    </span>
    <span class="image-warpper pull-left">
        <img class="img-responsive image"
             src="{{match.groomSoulmate.photo}}"
        />
    </span>
  </div>
  <div >
    <h4 class="list-group-item-heading">{{match.brideSoulmate.name}} &amp; {{match.groomSoulmate.name}}</h4>
    <span class="badge">{{matchStatus}}</span>
  </div>
</a>
