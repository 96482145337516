<div class='search'>
  <input class="form-control" type="text" name="search" #searchInput
      placeholder="{{placeholder}}" (keyup)="onKeyup($event, dropDown)" autocomplete="off">
  <div class="dropdown" [ngClass]="{'open':autoCompleteResults!=null}"  >
    <ul class="dropdown-menu " #dropDown>
      <li *ngFor="let result of autoCompleteResults;let idr = index" 
        [ngClass]="{'focused':selectedAutoCompleteResult == idr}" 
        (mouseover)="mouseoverResult(result)">
        <!-- if simple field -->
        <a *ngIf="result.values==null&&result.lookupUrl==null"
          (keyup.enter)="onSelectResult(searchInput, result)"
          (click)="onSelectResult(searchInput, result)" tabindex='0' >
            <strong>{{result.fieldString}}</strong> {{result.value}}
        </a>
        <!-- if m2o field -->
        <a *ngIf="result.lookupUrl!=null" (keyup.enter)="performLookup(result)"
          (click)="performLookup(result)" tabindex="0">
          <span class="fa fa-caret-right" aria-hidden="true"></span>
          <strong>{{result.fieldString}} </strong></a>
        <a *ngIf="result.values!=null" >
          <span class="fa fa-caret-down" aria-hidden="true"></span>
          <strong>{{result.fieldString}} </strong></a>
        <ul class="secondary-menu " *ngIf="result.values">
          <li *ngFor="let value of result.values;let idv = index" 
            [ngClass]="{'focused':(selectedAutoCompleteValue == idv) && (selectedAutoCompleteResult == idr)}" 
            (mouseover)="mouseoverValue(value)">
            <a (keyup.enter)="onSelectResult(searchInput, result, value)"
              (click)="onSelectResult(searchInput, result, value)" tabindex='0'>{{value[1]}}</a>
          </li>
        </ul>

      </li>

    </ul>
  </div>
</div>
<div>
  <span class="col-md-12 row">
    <div class='col-md-8'>
      <div class=" search" *ngIf="appliedFilters.length>0">
        <strong> Searching by: </strong>
        <div class="badge blue" *ngFor="let filter of appliedFilters">
          <strong>{{filter.fieldString}}</strong> {{filter.valueString}}
          <a class="dismiss-badge" (click)="removeFilter(filter)">  <i class="remove fa fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="col-md-4 form-inline">
      <div class="form-group pull-right row">
        <label class="control-label">Sort By</label>
        <select class="form-control input-sm" (change)="setSortFilter($event.target.value)">
          <option *ngFor="let item of sortFields;let i=index;" value="{{i}}">{{item.orderString}}</option>
        </select>
      </div>
    </div>
  </span>
</div>
